import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import hotelImage from "../../Images/motel-small-6.jpg";
import "./Contact.css";
import emailjs from "@emailjs/browser";

const SERVICE_ID = "service_v2vo0kb";
const TEMPLATE_ID = "template_4bbj4xo";
const PUBLIC_KEY = "f3S8_C8RacQZvwh3i";

const Contact = () => {
  const [userMessage, setUserMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const formLarge = useRef();
  const formSmall = useRef();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [arrivalDate, setArrivalDate] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [numberOfGuests, setNumberOfGuests] = useState("");
  const [message, setMessage] = useState("");
  const [emailSubject, setEmailSubject] = useState("");

  const handleSubmit = (e, formRef) => {
    e.preventDefault();

    const sendEmail = async () => {
      emailjs
        .sendForm(SERVICE_ID, TEMPLATE_ID, formRef.current, {
          publicKey: PUBLIC_KEY,
        })
        .then(
          (response) => {
            console.log("SUCCESS!", response.text);
            setUserMessage("Your information has been sent successfully.");
            setSuccess(true);
            formRef.current.reset();
          },
          (error) => {
            console.log("FAILED...", error);
            setUserMessage("Something went wrong. Please try sending email directly to email listed above.");
            setSuccess(false);
          }
        );
    };

    sendEmail();
  };

  return (
    <div className="wrapper">
      <section className="contact-section-one">
        <img src={hotelImage} alt="hotel" />
        <article className="contact-section-text">
          <h1>Burton's Sunset Motel Information</h1>
          <h3>Burton's Sunset Oasis Motel</h3>
          <p>105 Money Point Road</p>
          <p>Bay St Lawrence</p>
          <p>Nova Scotia B0C1R0</p>
          <div>
            <h3>Phone: (902) 383-2666</h3>
            <h3>Fax: (902) 383-2669</h3>
          </div>
          <div>
            <p>Send us an email at the address below</p>
          </div>
          <div>
            <p>
              <Link to="/location" className="link-to-location-page">
                Get Directions to the Motel
              </Link>
            </p>
          </div>
        </article>
      </section>
      <div className="flex-center">
        <h2 className="email-title">
          Send Us an Email:
          <p> burtons@burtonsoasismotel.ca</p>
        </h2>
        <div className="large-form-container">
          <form ref={formLarge} className="form-large">
            <div className="form-large-section-one">
              <div className="form-labels">
                <label>Email Subject:</label>
                <label>Name:</label>
                <label>Email Address</label>
                <label>Phone Number:</label>
                <label>Address:</label>
                <label>Arrival Date:</label>
                <label>Departure Date:</label>
                <label>Number of Guests:</label>
              </div>
              <div className="form-inputs">
                <select name="emailSubject" value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)}>
                  <option value="">Please Select</option>
                  <option value="inquiry">Inquiry</option>
                  <option value="reservation">Reservation</option>
                  <option value="feedback">Feedback</option>
                </select>
                <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} />
                <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                <input type="tel" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                <input type="text" name="address" value={address} onChange={(e) => setAddress(e.target.value)} />
                <input type="date" name="arrivalDate" value={arrivalDate} onChange={(e) => setArrivalDate(e.target.value)} />
                <input type="date" name="departureDate" value={departureDate} onChange={(e) => setDepartureDate(e.target.value)} />
                <input
                  type="number"
                  name="numberOfGuests"
                  value={numberOfGuests}
                  onChange={(e) => setNumberOfGuests(e.target.value)}
                  min="1"
                  max="10"
                />
              </div>
            </div>
            <div className="form-large-section-two">
              <label>Message:</label>

              <textarea name="message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>

              <p>* An email address or phone number are required in order for us to respond.</p>
            </div>
            <p className={success ? "success" : "error"}>{userMessage}</p>
          </form>
          <button onClick={(e) => handleSubmit(e, formLarge)}>Submit</button>
        </div>
        <div className="small-form-container">
          <form ref={formSmall} className="form-small">
            <label>Email Subject:</label>
            <select name="emailSubject" value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)}>
              <option value="">Please Select</option>
              <option value="inquiry">Inquiry</option>
              <option value="reservation">Reservation</option>
              <option value="feedback">Feedback</option>
            </select>
            <label>Name:</label>
            <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} />
            <label>Email Address</label>
            <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            <label>Phone Number:</label>
            <input type="tel" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} required />
            <label>Address:</label>
            <input type="text" name="address" value={address} onChange={(e) => setAddress(e.target.value)} />
            <label>Arrival Date:</label>
            <input type="date" name="arrivalDate" value={arrivalDate} onChange={(e) => setArrivalDate(e.target.value)} />
            <label>Departure Date:</label>
            <input type="date" name="departureDate" value={departureDate} onChange={(e) => setDepartureDate(e.target.value)} />
            <label>Number of Guests:</label>
            <input type="number" name="numberOfGuests" value={numberOfGuests} onChange={(e) => setNumberOfGuests(e.target.value)} min="1" max="10" />
            <label>Message:</label>

            <textarea name="message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>

            <p>* An email address or phone number are required in order for us to respond.</p>

            <p className={success ? "success" : "error"}>{userMessage}</p>
          </form>
          <button onClick={(e) => handleSubmit(e, formSmall)}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
